<template>
	<div>

		<div class="sub-tit reading int"><p>참여예정 프로젝트</p></div>

		<div class="contents-body demand prj">
			<ProjectInfo :projectSeq="input.projectSeq" :tecMberSeq="input.tecMberSeq" type="103" @goList="$router.go(-1)"/>
		</div>

	</div>

</template>

<script>

/**
 * 기술인재의 참여예정 프로젝트 상세
 */
import ProjectInfo from '@/components/highpro/ProjectInfo.vue';

export default {

	components : {
		ProjectInfo
	},

	props: {
        tecMberSeq : String,
		projectSeq : String  
    },

	data() {
		return {
			input: {
				tecMberSeq : '',
				projectSeq : 0,
			}, // 검색조건	
			
			data : {},
		};
	},
	beforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');

		if(this.$route.query.tecMberSeq != undefined && this.$route.query.tecMberSeq != '') {
			this.input.tecMberSeq = this.$route.query.tecMberSeq;
		} else {
			this.input.tecMberSeq = this.tecMberSeq;
		}

		if(this.$route.query.projectSeq != undefined && this.$route.query.projectSeq != '') {
			this.input.projectSeq = Number(this.$route.query.projectSeq);
		} else {
			this.input.projectSeq = Number(this.projectSeq);
		}
	},
	mounted() {
		// console.log('mounted');
		this.$emit('searchEnd');
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('update');
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {

	},
};
</script>
